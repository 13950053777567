let reviewsMap = {};

const reviewTemplate = '<div class="shadow-lg shadow-black rounded-2xl flex flex-col gap-5 w-full md:w-96 p-12 bg-gray-900 items-center review-card">\n' +
    '            <div class="flex flex-col md:flex-row gap-3 items-center">\n' +
    '                <img src="[img]" class="w-16" alt="recenzent" referrerpolicy="no-referrer">\n' +
    '                <div class="text-center md:text-left">\n' +
    '                    <div class="font-bold">[name]</div>\n' +
    '                    <div class="text-sm text-white">[date]</div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <div>\n' +
    '                [stars]\n' +
    '            </div>\n' +
    '            [text]\n' +
    '        </div>';

function showFullReviews() {
    for(let key of Object.keys(reviewsMap)) {
        document.getElementById(key).innerHTML = reviewsMap[key];
    }
}
